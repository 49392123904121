import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { PageLink } from "../components/link";

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      sanityGlobal {
        contact {
          addressLine1
          addressLine2
          city
          email
          phoneNumber
          state
          zip
        }
      }
    }
  `);
  const {
    addressLine1,
    addressLine2,
    city,
    email,
    phoneNumber,
    state,
    zip,
  } = data.sanityGlobal.contact;

  const [viewport, setViewport] = useState({
    width: 800,
    height: 400,
    latitude: 43.7056,
    longitude: -72.23212,
    zoom: 10.83,
  });

  return (
    <div className="max-w-6xl w-full mx-auto md:my-10 ">
      <div className="bg-white p-8 overflow-hidden max-w-full">
        <h1 className="text-center pb-8">Contact</h1>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 mb-10 pr-8">
            <p className="mb-0">{addressLine1}</p>
            <p className="mb-0">{addressLine2}</p>
            <p className="mb-0">
              {city}, {state} {zip}
            </p>
            <div>
              <p className="mb-0">
                <PageLink
                  type="externalLink"
                  to={`mailto:${email}`}
                  className="hover:underline transition-all duration-150"
                >
                  {email}
                </PageLink>
              </p>
              <p className="mb-0">
                <PageLink
                  type="externalLink"
                  to={`tel:${phoneNumber}`}
                  className="hover:underline transition-all duration-150"
                >
                  {phoneNumber}
                </PageLink>
              </p>
            </div>
          </div>
          <div className="flex-1">
            <ReactMapGL
              {...viewport}
              className="w-full"
              onViewportChange={setViewport}
              mapStyle="mapbox://styles/akievet/ckiot7enq0gfs17moybeg5d9p"
              mapboxApiAccessToken={process.env.GATSBY_MAPBOX_API}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
